::selection {
    color: #fff;
    background: #000d8f
}

a,
.btn-link {
    color: #000d8f
}

.text-green {
    color: #309975 !important
}
.text-success {
    color: #58b368 !important
}
.text-danger {
    color: #db2828 !important
}
.text-primary {
    color: #000d8f !important
}
.text-warning {
    color: #dfba49 !important
}
.text-info {
    color: #17a2b8 !important;
}
.mail-star.active,
.page-title {
    color: #000d8f !important
}

.badge-default {
    background: #3f3d56;
    color: #f1f1f1
}

.loader {
    color: #000d8f
}

.badge-primary {
    background-color: #000d8f
}

.btn-primary {
    background: #000d8f;
    color: #fff;
    border-color: #000d8f
}

.btn-info {
    background-color: #e8769f;
    border-color: #e8769f
}

.nav-tabs .nav-link.active {
    border-color: #000d8f;
    color: #000d8f
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 82, 120, 0.25)
}

.card,
.dropify-wrapper,
.btn-primary {
    border: 0
}

.bg-primary {
    background-color: #2D5F8B !important;
}
.bg-primary th {
    color: wheat !important;
}
.bg-info,
.bg-warning,
.btn-success {
    background-color: #000d8f !important;
    border: transparent
}

.bg-success,
.bg-danger,
.badge-success,
.progress-bar,
.btn-danger,
.btn-warning {
    background-color: #e8769f;
    border: transparent
}

.btn-outline-primary {
    color: #000d8f;
    border-color: #000d8f
}

.btn-outline-primary:hover {
    background-color: #000d8f;
    border-color: #000d8f
}

.custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    border-color: #000d8f;
    background-color: #000d8f
}

.custom-switch-input:checked~.custom-switch-indicator {
    background: #000d8f
}

.selectgroup-input:checked+.selectgroup-button {
    border-color: #000d8f;
    color: #000d8f;
    background: rgba(90, 82, 120, 0.2)
}

.imagecheck-figure:before {
    color: #e8769f !important
}

.wizard>.steps .current a,
.wizard>.steps .current a:hover,
.wizard>.steps .current a:active,
.wizard>.actions a,
.wizard>.actions a:hover {
    background: #000d8f
}

.wizard>.steps .done a,
.wizard>.steps .done a:hover {
    background: rgba(90, 82, 120, 0.2);
    color: #000d8f
}

.table th {
    color: #2c2945
}

.table.table-custom td,
.table.table-custom th {
    background: #f1f1f1
}

.right_chat li.online .status {
    background: #000d8f
}

.bg-blue,
.bg-azure,
.bg-indigo,
.bg-purple,
.bg-red,
.bg-orange {
    background-color: #000d8f !important
}

.bg-pink,
.bg-yellow,
.bg-lime,
.bg-green,
.bg-teal,
.bg-cyan {
    background-color: #e8769f !important
}

.bg-light-blue,
.bg-light-azure,
.bg-light-indigo,
.bg-light-lime,
.bg-light-green,
.bg-light-teal {
    background-color: rgba(90, 82, 120, 0.1);
    color: #000d8f
}

.bg-light-pink,
.bg-light-red,
.bg-light-orange,
.bg-light-yellow,
.bg-light-cyan {
    background-color: rgba(232, 118, 159, 0.1);
    color: #e8769f
}

.bg-light-purple {
    background-color: rgba(228, 189, 81, 0.1);
    color: #e4bd51
}

.bg-light-gray {
    background-color: rgba(102, 106, 109, 0.1);
    color: #666A6D
}

.avatar.avatar-blue {
    background-color: rgba(90, 82, 120, 0.1);
    color: #000d8f
}

.avatar.avatar-azure {
    background-color: rgba(90, 82, 120, 0.1);
    color: #000d8f
}

.avatar.avatar-indigo {
    background-color: rgba(90, 82, 120, 0.1);
    color: #000d8f
}

.avatar.avatar-purple {
    background-color: rgba(90, 82, 120, 0.1);
    color: #000d8f
}

.avatar.avatar-pink {
    background-color: rgba(90, 82, 120, 0.1);
    color: #000d8f
}

.avatar.avatar-red {
    background-color: rgba(90, 82, 120, 0.1);
    color: #000d8f
}

.avatar.avatar-orange {
    background-color: rgba(90, 82, 120, 0.1);
    color: #000d8f
}

.avatar.avatar-yellow {
    background-color: rgba(232, 118, 159, 0.1);
    color: #e8769f
}

.avatar.avatar-lime {
    background-color: rgba(232, 118, 159, 0.1);
    color: #e8769f
}

.avatar.avatar-green {
    background-color: rgba(232, 118, 159, 0.1);
    color: #e8769f
}

.avatar.avatar-teal {
    background-color: rgba(232, 118, 159, 0.1);
    color: #e8769f
}

.avatar.avatar-cyan {
    background-color: rgba(232, 118, 159, 0.1);
    color: #e8769f
}

body {
    background-color: #f1f1f1;
    color: #2c2945
}

.page-header .right .nav-pills .nav-link {
    color: #2c2945
}

/* #header_top .brand-logo {
    background: #000d8f
} */

.header {
    background: #000d8f
}

.header .nav-tabs .nav-link {
    color: #fff
}

.header .nav-tabs .nav-link.active {
    border-color: #fff
}

.header .dropdown-menu .dropdown-item:hover,
.header .dropdown-menu .dropdown-item.active {
    color: #000d8f
}

.page {
    background: url('/assets/images/logo-bg.png') no-repeat center;
    background-size: 100%;
    min-height: 100vh;
}

.page .section-white,
.page .section-body {
    background: #f1f1f1;
}

#header_top .nav-link {
    color: wheat
}

.header_top.dark {
    background-color: #39374e !important
}

.header_top.dark .nav-link {
    color: #f1f1f1 !important
}

.card {
    background-color: #fff
}

.card .card-options a {
    color: #000d8f
}

.card .card-options a.btn {
    color: #fff
}

.card.card-fullscreen {
    background-color: #fff !important
}

.metismenu a {
    color: wheat
}

.metismenu a:hover {
    color: #ffffff;
    font-weight: bolder;
    border-color: #fff
}

.metismenu .active>a {
    color: #ffffff;
    border-color: #ffffff
}

.metismenu .active ul .active a {
    color: #ffffff;
    background: transparent
}

.metismenu.grid>li.active>a,
.metismenu.grid>li>a:hover {
    background: rgba(90, 82, 120, 0.1);
    color: #ffffff
}

.inbox .detail {
    background: #fff
}

.file_folder a {
    background: #fff;
    border-color: #f1f1f1
}

.auth .auth_left {
    background: #fff
}

.gender_overview {
    background: #f1f1f1
}

.table-calendar-link {
    background: #000d8f
}

.table-calendar-link:hover {
    background: #39374e
}

.table-calendar-link::before {
    background: #39374e
}

.theme_div {
    background: #fff
}

.ribbon .ribbon-box.green {
    background: #cedd7a
}

.ribbon .ribbon-box.green::before {
    border-color: #cedd7a;
    border-right-color: transparent
}

.ribbon .ribbon-box.orange {
    background: #000d8f
}

.ribbon .ribbon-box.orange::before {
    border-color: #000d8f;
    border-right-color: transparent
}

.ribbon .ribbon-box.pink {
    background: #e8769f
}

.ribbon .ribbon-box.pink::before {
    border-color: #e8769f;
    border-right-color: transparent
}

.ribbon .ribbon-box.indigo {
    background: #e4bd51
}

.ribbon .ribbon-box.indigo::before {
    border-color: #e4bd51;
    border-right-color: transparent
}

.text-orange {
    color: #e4bd51 !important
}

.tag-info {
    background: #000d8f
}

.tag-success,
.tag-green {
    background: #cedd7a
}

.tag-orange {
    background: #e8769f
}

.tag-danger {
    background: #e8769f
}

.bg-success {
    background: #cedd7a !important
}

.bg-danger {
    background-color: #e8769f !important
}

.auth .auth_left {
    background: #0069d9
}

.auth .auth_left .card {
    padding: 20px;
    right: -150px;
    z-index: 99999
}

.auth .auth_left .card.has-error {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

@media only screen and (max-width: 1024px) {
    .auth .auth_left .card {
        right: -50px
    }
}

@media only screen and (max-width: 992px) {
    .auth .auth_left .card {
        right: auto
    }
}

.top_dark {
    background-color: #39374e !important
}

.sidebar_dark #left-sidebar {
    background-color: #39374e !important
}

.sidebar_dark #header_top .nav-link {
    color: #f1f1f1 !important
}

.sidebar_dark .sidebar .metismenu .active>a {
    color: #e4bd51
}

.sidebar_dark .sidebar .metismenu .active ul .active a {
    color: #e4bd51
}

.iconcolor #header_top .nav-link {
    color: #e4bd51 !important
}

.gradient .custom-switch-input:checked~.custom-switch-indicator {
    background: linear-gradient(45deg, #000d8f, #e8769f) !important
}

.gradient .metismenu.grid>li.active>a,
.gradient .metismenu.grid>li>a:hover {
    background: linear-gradient(45deg, #000d8f, #000d8f) !important;
    color: #fff;
    border: 0
}

.gradient .btn-primary {
    background: linear-gradient(45deg, #000d8f, #e8769f) !important;
    border: 0
}

.gradient .btn-dark {
    background: linear-gradient(45deg, #808488, #333537) !important
}

.gradient .bg-success,
.gradient .bg-danger,
.gradient .badge-success,
.gradient .progress-bar,
.gradient .btn-danger {
    background: linear-gradient(45deg, #000d8f, #e8769f) !important
}

.metismenu.grid>li>a {
    border-color: #000d8f
}