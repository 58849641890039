.fc-event-container .fc-event,.fc-event-container .fc-event-dot {
    background-color: #39374e !important;
    color: #BABDBF!important;
    border-color:  #39374e !important;
}

.nav-link-gdc-selected {
    font-weight: bold !important;
}

div.font-montserrat{
    font-family:"Montserrat",sans-serif
}
div.font-roboto{
    font-family:"Robot",sans-serif
}
div.font-opensans{
    font-family:"Open Sans",sans-serif;
    font-size:14px
}
/* div.rtl{ */
div.rtl{
    direction: rtl !important;   
    text-align: right;
}
.sticky-top {
    width: calc(100% - 280px);
    position: fixed;
}
.marginTop{
    margin-top: 7.2%!important;
}
/* .header_top {
    background-color: #2D5F8B;
} */
/* #left-sidebar {
    background-color: #4276A4;
} */
.metismenu .g_heading {
    color: #ccc;
}
.metismenu ul a {
    color: wheat;
}
.bg-admin-pink {
    background-color: rgb(253 0 99 / 1);
}
.bg-admin-orange {
    background-color: rgb(242 62 20 / 1);
}
.bg-admin-purple {
    background-color: rgb(106 69 254 / 1);
}
.bg-admin-blue {
    background-color: rgb(66 110 255 / 1);
}
.tracking-wide {
    letter-spacing: 0.025em;
    font-size: 1.17em;
}
.w-12 {
    width: 3rem;
}

.h-12 {
    height: 3rem;
}

.rounded-full {
    border-radius: 9999px;
}
.gap-4 {
    gap: 1rem;
}

.eshop-dashboard .p-4 {
    padding: 1rem !important;
}

.eshop-dashboard .rounded-lg {
    border-radius: 0.5rem !important;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-admin-orange {
    --tw-text-opacity: 1;
    color: rgb(242 62 20 / var(--tw-text-opacity));
}
.text-admin-purple {
    --tw-text-opacity: 1;
    color: rgb(106 69 254 / var(--tw-text-opacity));
}
.text-admin-blue {
    --tw-text-opacity: 1;
    color: rgb(66 110 255 / var(--tw-text-opacity));
}
.text-admin-pink {
    --tw-text-opacity: 1;
    color: rgb(253 0 99 / var(--tw-text-opacity));
}
.bg-admin-orange\/10 {
    background-color: rgb(242 62 20 / 0.1);
}

.thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
}

.reporting-table td, .reporting-table th {
    padding: 0 ;
}

.card-header {
    background-color: #2D5F8B;
    color: wheat;
    padding: 10px 20px !important;
}
.card .card-options a {
    color: wheat;
}
.card .input-group-btn>.btn{
    color: wheat !important;
    border-color: wheat !important;
}

/* .offcanvas-active #header_top .nav-link {
    color: #2D5F8B;
} */
.coop-logo {
    position: relative;
    cursor: pointer;
}
.select-logo {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.coop-logo:hover .avatar {
    opacity: 0.3;
}
.coop-logo:hover .select-logo {
    opacity: 1;
}
.customSelect {
    width: 100%;
    /* height: 50px !important; */
}

.register {
    height: auto;
}

.register .auth_right{
    text-align:left;
    padding: 20px
}

.register .auth_right img{
    width: auto;
}

.register .auth_right .header-brand {
    display: none;
}

@media screen and (max-width: 767px){.register .auth_left{display:none}}

@media screen and (max-width: 767px){
    .register .auth_right{
        display:block;
        width:100%;
        /* text-align:center */
    }
    .register .auth_right .header-brand {
        display: block;
    }
}

/* 
#deleteModal .modal-dialog {
    -webkit-transform: translate(0,-50%);
    -o-transform: translate(0,-50%);
    transform: translate(0,-50%);
    top: 50%;
    margin: 0 auto;
} */

.employee-welcome-card {
    margin-bottom: 24px;
    position: relative;
}
.employee-welcome-card:before {
    border-radius: 0 0 10px 0;
    content: "";
    height: 100%;
    position: absolute;
    right: 20px;
    top: 0;
    transform: skew(12deg);
    width: 100px;
}

.employee-welcome-card, .employee-welcome-card:before {
    background: linear-gradient(90.31deg, #ff902f -1.02%, #ff2d3d 132.59%);
}

.employee-welcome-card .card-body {
    padding: 24px;
}
.employee-welcome-card .welcome-info {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-pack: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    margin-bottom: 24px;
    position: relative;
    z-index: 1;
}
.employee-welcome-card .welcome-info .welcome-content h4 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 5px;
}
.employee-welcome-card .welcome-info .welcome-content p {
    color: #eff0f1;
    margin-bottom: 0;
}
.employee-welcome-card .welcome-info .welcome-content p span {
    border-bottom: 1px solid #fff;
    color: #fff;
    font-weight: 500;
    padding-bottom: 3px;
}
.employee-welcome-card .welcome-btn {
    position: relative;
    z-index: 1;
}
.employee-welcome-card .welcome-info .welcome-img, .employee-welcome-card .welcome-info .welcome-img img {
    border-radius: 50%;
    height: 45px;
    width: 45px;
}
.employee-welcome-card .welcome-btn {
    position: relative;
    z-index: 1;
}
.employee-welcome-card .welcome-btn .btn {
    background: #ffebda;
    border: 1px solid #ffaf69;
    border-radius: 5px;
    color: #373b3e;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    min-width: 110px;
    padding: 9px 12px;
}